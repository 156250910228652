import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import axios from "axios";
import profileimg from './../../assets/images/profile-img.png'
import google from './../../assets/images/googlesing.png'
import {  Box, Button,  Grid2,   Modal, } from "@mui/material";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};
const MyProfilePage = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [successchanged, setsuccesschanged] = useState(false);
    const [VideoGeneration, setVideoGeneration] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger, reset, setValue } = useForm({});

    // const login = () => {
    //     sessionStorage.setItem('Admintoken', "welcome")
    //     navigate("/")
    // }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }


    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('fullname', data.fullname);
        formData.append('mobile', data.mobileNumber);
        if(data.profile_image[0]){
            formData.append('profile_image', data.profile_image? data.profile_image[0] : "");
        }
        
         
        let userinfo = JSON.parse(sessionStorage.getItem("user-info"))
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }
        axios.put(`/apiV1/user-info/`, formData, config).then(
            (res) => {
                setVideoGeneration(true)
                setIsSubmitting(false);

            }
        ).catch((e) => {
            setIsSubmitting(false);
        });
    }
    const [passwordEye, setpasswordEye] = useState(false)
    const handlePassword = () => {
        setpasswordEye(!passwordEye)
    }
    const [passwordEye2, setpasswordEye2] = useState(false)
    const handlePassword2 = () => {
        setpasswordEye2(!passwordEye2)
    }
    const [passwordEye3, setpasswordEye3] = useState(false)
    const handlePassword3 = () => {
        setpasswordEye3(!passwordEye3)
    }
    const [profileimageUrl, setprofileimageUrl] = useState()
    const getUserinfo = () => {
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }
        axios.get(`/apiV1/user-info/`, config).then(
            (res) => {
                setValue("username", res.data.username)
                setValue("mobileNumber", res.data.mobile)
                setValue("fullname", res.data.fullname)
                setprofileimageUrl(res.data.profile_image)
            }
        ).catch((e) => {
            setIsSubmitting(false);
        });
    }
    useEffect(()=>{
        getUserinfo();
    }, [])
    return (
        <div className="" style={{ paddingTop: "20px" }}>

            <div className="row ">
                <div className="col-md-2 col-lg-2">

                </div>

                <div className="col-md-8 col-lg-8 mt-4 ">
                    <form onSubmit={handleSubmit(Submit)}>
                    <div className="card login-box-container" >
                        <div className="card-body">
                            <h2 className="mb-4" style={{ color: "#000" }}>Account</h2>
                            <h4 style={{ color: "#000" }}>Profile</h4>
                            <div className="profileHolder">
                                <div className="profileInner">
                                    <div className="profilePhoto">
                                        <img src={profileimageUrl ? profileimageUrl :profileimg} ></img>
                                    </div>
                                    <div className="uploadButtons">
                                        <input type="file" id="profilephoto"  {...register("profile_image", { required: profileimageUrl ? false : true , })} style={{display:"none"}} />
                                        <label   disabled={isSubmitting} className="btn btn-info m-b-xs" htmlFor="profilephoto" >Upload</label>
                                        {/* <button type="button" disabled={isSubmitting} className="btn btn-error m-b-xs" >Remove</button> */}
                                    </div>


                                </div>
                                {errors.profile_image && (<small id='documentPanNumber' className='form-text text-danger'>  This field is required</small>)}
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    

                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="fullname" placeholder="Fullname" {...register("fullname", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: /^[A-Za-z][A-Za-z-\s]+$/,
                                                        message: "Please enter a valid name.",
                                                    },
                                                    // minLength: {
                                                    //   value: 3,
                                                    //   message: "Please enter min 3 characters ",
                                                    // },
                                                })}
                                                    onKeyUp={() => {
                                                        trigger("fullname");

                                                    }} />
                                                <label htmlFor="fullname">Fullname</label>
                                            </div>
                                            {errors.fullname && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.fullname.message} </small>)}
                                        </div>
                                        <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="username" placeholder="Username" {...register("username", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Za-z][A-Za-z-\s]+$/,
                                                message: "Please enter a valid username ",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("username");

                                            }} />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                    {errors.username && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.username.message} </small>)}
                                </div>
                                         
                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="mobileNumber" placeholder="name@example.com"   {...register("mobileNumber", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: /^[1-9]{1}[0-9]{9,11}$/,
                                                        message:
                                                            "Please enter a valid mobile number",
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "Please enter min 10 numbers ",
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: "Please enter max 12 numbers ",
                                                    },
                                                })}
                                                    onKeyUp={(e) => {
                                                        trigger("mobileNumber");
                                                        // setmobileNumberError(false)
                                                        // checkMobileNumberValid(e.target.value)
                                                    }}
                                                    minLength={10}
                                                    maxLength={10} />
                                                <label htmlFor="mobileNumber">Mobile Number</label>
                                            </div>
                                            {errors.mobileNumber && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.mobileNumber.message} </small>)}
                                        </div>
                                        

                                        {isSubmitting ?
                                            <div className='text-center'>
                                                <div className='spinner-grow text-primary' role='status'>
                                                    <span className='sr-only'>Loading...</span>
                                                </div>
                                            </div> : ""}

                                        
                                </div>
                            </div>
                            <hr/>
                            <div className="linkaccounts">
                                <h2 className="mb-2" style={{ color: "#000" }}>Linked Accounts</h2>
                                <p style={{ color: "#00000080" }}>We use this to let you sign in and populate your profile information</p>

                                <div className="row">
                                    <div className="col-md-6 col-lg-6">
                                            <img src={google} />
                                    </div>
                                    <div className="col-md-6 col-lg-6 text-end">
                                        <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs" >Connect</button>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="linkaccounts">
                                <h2 className="mb-2" style={{ color: "#000" }}>Delete Account</h2>
                                <p style={{ color: "#00000080" }}>By deleting your account you will loose all your data</p>
                            </div>
                            <div className="text-end">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs" >Save Changes</button>
                                            <button type="submit" disabled={isSubmitting} className="btn btn-error m-b-xs" >Cancel</button>
                                            {/* <button className="btn btn-primary">Facebook</button> */}
                                        </div>
                        </div>
                        
                    </div>

                    
                                   
                    </form>
                </div>
            </div>
            <Modal open={VideoGeneration} onClose={() => setVideoGeneration(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style, width: 500 }}>
                        <Grid2 container spacing={2} textAlign={"center"}>
                             
                            <Grid2 size={12}>
                             user details updated successfully
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                                <Button variant="contained" sx={{ mx: 2 }} onClick={() => setVideoGeneration(false)}>Ok</Button>

                            </Grid2>
                        </Grid2>
                    </Box>
                </Modal>
        </div>


    )
}

export default MyProfilePage