import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
import { useForm } from 'react-hook-form';
import axios from "axios";
const LoginPage = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger  } = useForm({});
    const navigate = useNavigate()
    // const login = () => {
    //     sessionStorage.setItem('Admintoken', "welcome")
    //     navigate("/")
    // }
    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        let req = {
            email: data.Email,
            password: data.Password
        }

        if (data.rememberme) {
            sessionStorage.setItem("username", data.Email)
            sessionStorage.setItem("Password", data.Password)
        } else {
            sessionStorage.removeItem("username")
            sessionStorage.removeItem("Password")
        }
        axios.post('/apiV1/login/', req).then(
            (res) => {
                const config = {
                    headers: { Authorization: `Bearer ${res.data.access}`, 'content-type': 'multipart/form-data' }
                  }
                  let accesstoken = res.data.access
                axios.get('/apiV1/user-info/', config).then((res2) => {
                    setIsSubmitting(false);
                    
                    sessionStorage.setItem('Admintoken', accesstoken)
                    
                    sessionStorage.setItem('user-info', JSON.stringify(res2.data))
                    navigate("/")
                     
                  }).catch((e) => { 
                    setIsSubmitting(false);
                    if (e.response.data.message) {
                      setLoginEmailerror(e.response.data.message)
                    }
                    
                  });
                 
            }
        ).catch((e) => {
            setIsSubmitting(false);
            if (e.response.data.detail) {
                setLoginEmailerror(e.response.data.detail[0])
            }

        });
    }
    const [passwordEye, setpasswordEye] = useState(false)
    const handlePassword = () => {
        setpasswordEye(!passwordEye)
    }
    return (
        <div className="container" style={{ paddingTop: "10%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chat Agent</p>
                                <p>Please Sign-in to your account.</p>
                            </div>

                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="mb-3">
                                    <div className="form-floating">

                                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" {...register("Email", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                                message: "Please enter a valid Email ID",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Email");
                                                setLoginEmailerror("");
                                            }} />
                                        <label htmlFor="floatingInput">Email address</label>
                                    </div>
                                    {errors.Email && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Email.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type={passwordEye ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" {...register("Password", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Password");
                                                setLoginEmailerror("");
                                            }} />
                                            <div className="passwordEye"  onClick={()=> handlePassword()}><i className={passwordEye ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>
                                    {errors.Password && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Password.message} </small>)}
                                    {loginEmailerror && (<small id='documentPanNumber' className='form-text text-danger'>  {loginEmailerror} </small>)}

                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>

                                    <Link to="/forgotpassword" style={{marginLeft:"100px"}}>Forgot Password</Link>
                                </div>
                                <div className="mb-3 form-check">
                                   
                                </div>
                                {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}

                                <div className="d-grid">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs bluebuttoncustom" >Sign In</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>
                            <div className="authent-reg">
                                <p>Not registered? <Link to="/register">Create an account</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default LoginPage