import React from "react";
 
import profileimage from './../assets/images/profile-bg.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate, useLocation } from "react-router-dom";
const TopHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const handleClose = () => {
        sessionStorage.removeItem("Admintoken")

        sessionStorage.removeItem("user-info")

        setAnchorEl(null);
        navigate("/login")
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
        navigate("/oldpasswordchange")
    }
    const handleClose3 = () => {
        navigate("/myprofile")
    }
    return (
        <>


            <div className="page-header inmobilehide">
                <nav className="navbar navbar-expand-lg d-flex justify-content-between ">
                    <div className="" id="navbarNav">
                        <Link className="navbar-brand" to="/"></Link>

                    </div>
                    <div className="logo">
                        <div className="" id="navbarNav">
                            <ul className="navbar-nav" id="leftNav">
                                 
                                <li className="nav-item">
                                    <Link to="/"> Dashboard</Link>

                                </li>
                                <li className={location.pathname === "/" ? "nav-item " : "nav-item"}  >
                                    <Link to="/" className={location.pathname === "/" ? "bluebuttoncustom2 " : " "}   > AI Chat Agent</Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="/"> Usage and Plan</Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="/"> Help Support</Link>

                                </li>

                                {/* <li className="nav-item">
                            <Link to="/" >Settings</Link>
                             
                        </li>
                        <li className="nav-item">
                        <Link to="/" >Help</Link>
                             
                        </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="" id="headerNav">
                        {/* <Button   className="PreviewChartbot"  variant="contained" size="large"  >Preview Chartbot </Button>
                        */}
                        <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                          <a className="nav-link profile-dropdown" href="#" id="profileDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={profileimage} alt="" className="profilelogo" /></a>
                          <div className="dropdown-menu dropdown-menu-end profile-drop-menu" aria-labelledby="profileDropDown">
                           
                            
                            
                             
                            <a className="dropdown-item" href="#" onClick={handleClose3}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>Profile</a>
                            <a className="dropdown-item" href="#" onClick={handleClose2}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>Change password</a>
                           
                            <a className="dropdown-item" href="#" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Logout</a>
                          </div>
                        </li>
                        </ul>
                       
                        

                    </div>
                </nav>
            </div>
            <div className="mobile-nav">
                <ul className="navbar-nav" id="leftNav">
                     
                    <li className="nav-item">
                        <Link to="/"> Dashboard</Link>

                    </li>
                    <li className={location.pathname === "/" ? "nav-item active" : "nav-item"}  >
                        <Link to="/" > AI Chat Agent</Link>

                    </li>
                    <li className="nav-item">
                        <Link to="/"> Usage and Plan</Link>

                    </li>
                    <li className="nav-item">
                        <Link to="/"> Help Support</Link>

                    </li>

                    {/* <li className="nav-item">
                            <Link to="/" >Settings</Link>
                             
                        </li>
                        <li className="nav-item">
                        <Link to="/" >Help</Link>
                             
                        </li> */}
                </ul>
            </div>

        </>
    )
}

export default TopHeader