import { Box, Button, Grid2, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import IntentsComponent from "./intents";
import { Link } from "react-router-dom";
import boaticon from './../assets/images/chartbot-bg.jpg'
import CancelIcon from '@mui/icons-material/Cancel';

const InnerChartBot = () => {
    const [listofchartboats, setlistofchartboats] = useState([])
    const [editID, seteditID] = useState()
    const [webisturl, setwebisturl] = useState()
    const [errormsg, seterrormsg] = useState()
    const [websitcontent, setwebsitcontent] = useState("")
    const [startForm, setstartForm] = useState(false)
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }
    const id = obj["id"];
    const type = obj["type"];

    const GetChartboatsList = (id) => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        if (type == "ADVANCED_WITH_KB") {
            axios.get(`/apiV1/web-page/?chatbot_id=${id}&is_active=true`).then((rep) => {
                setlistofchartboats(rep.data)
            }).catch((e) => {

            })
        }
    }


    const websitscrapingAI = () => {
        if (webisturl) {
            let req = {
                url: webisturl
            }
            axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
            axios.post(`apiV1/scrap-page/`, req).then((rep) => {
                setwebsitcontent(rep.data)
            }).catch((e) => {

            })
        } else {
            alert("Please enter valid website url")
        }
    }
    const saveWebsitepagesListData = () => {
        seterrormsg("")
        if (webisturl && websitcontent) {
            let object = {
                url: webisturl,
                content: websitcontent,
                chatbot: id
            }
            axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
            if (editID) {
                axios.patch(`apiV1/web-page/${editID}/`, object).then((rep) => {
                    GetChartboatsList(id);
                    setwebisturl("")
                    setwebsitcontent("")
                    seteditID("")
                    setstartForm(false)
                    //settypesofcharts(rep.data)
                }).catch((e) => {
                    if (e.response.data.detail) {
                        seterrormsg(e.response.data.detail)
                    }
                })
            } else {
                axios.post('apiV1/web-page/', object).then((rep) => {
                    GetChartboatsList(id);
                    setwebisturl("")
                    setwebsitcontent("")
                    setstartForm(false)
                    //settypesofcharts(rep.data)
                }).catch((e) => {
                    if (e.response.data.detail) {
                        seterrormsg(e.response.data.detail)
                    }
                })
            }
        } else {
            alert("Please enter valid website url and website content")
        }
    }
    const onchangepageContentEdit = (data) => {
        setwebisturl(data.url)
        setwebsitcontent(data.content)
        seteditID(data.id)
        setstartForm(true)
    }
    const ondeletePageAndContent = (data) => {
        if (window.confirm("Are you sure you want to delete this page?")) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
            axios.delete(`apiV1/web-page/${data.id}/`).then((rep) => {
                GetChartboatsList(id)
            }).catch((e) => {

            })
        } else {

        }
    }
    const AddnewPagesToWithKB = () => {
        setwebisturl('')
        setwebsitcontent("")
        seteditID("")
    }
    useEffect(() => {

        GetChartboatsList(id)
    }, [])
    return (

        <Grid2 container spacing={2}>
            {type == "BASIC_WITHOUT_KB" ?
                <IntentsComponent id={id} type={type} /> : type == "ADVANCED_WITH_KB" ?

                    <>
                        <Grid2 size={{ xs: 12, md: 4, lg: 3 }} sx={{ mt: 4, }}>
                            <div className="card">
                                <div className="card-body minheightforviwe" >
                                    <Button variant="contained" fullWidth size="large" onClick={() => { AddnewPagesToWithKB(); setstartForm(true); seteditID(""); seterrormsg(""); setwebisturl(""); setwebsitcontent("") }} className="bluebuttoncustom" >+ Pages</Button>

                                    <div className="file-manager-menu">
                                        {/* <span className="fmm-title">My Drive</span> */}
                                        <ul className="list-unstyled">
                                            {listofchartboats.map((intent, i) => {
                                                return (
                                                    <li className={intent.id == editID ? "cursor-pointer d-flex active" : "cursor-pointer d-flex "} key={intent.id}>
                                                        <div onClick={() => onchangepageContentEdit(intent)}><i className="far fa-folder"></i> <small>{intent.url}</small> </div>
                                                        <div onClick={() => ondeletePageAndContent(intent, i)} className="text-end textrightpo" ><CancelIcon />   </div>
                                                    </li>
                                                )
                                            })}

                                        </ul>


                                    </div>
                                    <Button variant="contained" size="large" component={Link} to={`/chartbotwithkbintent?id=${id}&type=${type}`} className="bluebuttoncustom" >Continue Intents</Button>
                                </div>
                            </div>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 8, lg: 7 }} sx={{ mt: 4 }}>
                            <div className="card minheightforviwe">
                                <div className="card-body">

                                    {startForm ?
                                        <Box
                                            component="form"
                                            sx={{ '& .MuiTextField-root': { m: 1, } }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <div className="mb-3 text-left">
                                                    <label for="outlined-required" className="form-label">Website Url</label>
                                                    <input type="text" className="form-control" id="outlined-required" value={webisturl} onChange={(e) => { setwebisturl(e.target.value); }} placeholder="Website Url" />
                                                </div>
                                                <div className="mb-3 text-left">
                                                    <label for="outlined-required2" className="form-label">Content</label>
                                                    <div className="website-scraping-ai" onClick={() => websitscrapingAI()}>
                                                        Website Scraping AI
                                                    </div>
                                                    <textarea className="form-control"  id="outlined-required2" aria-label="With textarea" rows={26} value={websitcontent} onChange={(e) => setwebsitcontent(e.target.value)} placeholder="Content"></textarea>

                                                </div>





                                            </div>
                                            <Box display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%" sx={{ padding: "0px 10px" }}>
                                                {errormsg}
                                                <Button variant="contained" size="large" onClick={() => saveWebsitepagesListData()} className="saveButtonCustom" >Save</Button>
                                                <Button variant="contained" size="large" onClick={() =>{ setstartForm(false);  seteditID("");}} color="error"  >Cancel</Button>

                                            </Box>

                                        </Box>

                                        :
                                        <Box textAlign={"center"}>
                                            <img src={boaticon} style={{ maxWidth: "100%", height: "500px", margin: "0 auto" }} />
                                        </Box>}
                                </div>
                            </div>

                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 0, lg: 2 }} sx={{ mt: 4 }}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="chat-container bg-white">
                                        <div className="d-flex flex-column mb-3">

                                        </div>
                                        <div className="chat-input">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Grid2>
                    </>
                    : ""}
        </Grid2>
    )
}

export default InnerChartBot;