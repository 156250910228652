import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
import { useForm } from 'react-hook-form';
import axios from "axios";
const RegisterPage = () => {
    const { register, handleSubmit, formState: { errors }, trigger } = useForm({});
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signUpValidationErrors, setSignUpValidationErrors] = useState({})
    const Submit = (data) => {
        setIsSubmitting(true)
        let req = {
            fullname: data.fullname,
            email: data.Email,
            mobile: data.mobileNumber,

            username: data.username,

            password: data.enterPassword,
            confirm_password: data.confirmPassword,
        }
        axios.defaults.headers.common = { 'Authorization': `` }
        axios.post('apiV1/register/', req).then((rep) => {
            setIsSubmitting(false)
            navigate("/accountsuccess")
        }
        ).catch((e) => {
            setIsSubmitting(false)
            if (e.response.data) {
                setSignUpValidationErrors(e.response.data)
            }
        })
        sessionStorage.setItem('Admintoken', "welcome")
        
    }
    const [passwordEye, setpasswordEye] = useState(false)
    const handlePassword = () => {
        setpasswordEye(!passwordEye)
    }
    const [passwordEye2, setpasswordEye2] = useState(false)
    const handlePassword2 = () => {
        setpasswordEye2(!passwordEye2)
    }
    return (
        <div className="container" style={{ paddingTop: "8%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chart Agent</p>
                                <p>Enter your details to create your account</p>
                            </div>

                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="fullname" placeholder="Fullname" {...register("fullname", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Za-z][A-Za-z-\s]+$/,
                                                message: "Please enter a valid name.",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("fullname");

                                            }} />
                                        <label htmlFor="fullname">Fullname</label>
                                    </div>
                                    {errors.fullname && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.fullname.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="username" placeholder="Username" {...register("username", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Za-z][A-Za-z-\s]+$/,
                                                message: "Please enter a valid username ",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("username");

                                            }} />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                    {errors.username && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.username.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="email" className="form-control" id="Email" placeholder="name@example.com"  {...register("Email", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                                message: "Please enter a valid Email ID",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Email");

                                            }} />
                                        <label htmlFor="Email">Email address</label>
                                    </div>
                                    {errors.Email && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Email.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="mobileNumber" placeholder="name@example.com"   {...register("mobileNumber", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[1-9]{1}[0-9]{9,11}$/,
                                                message:
                                                    "Please enter a valid mobile number",
                                            },
                                            minLength: {
                                                value: 10,
                                                message: "Please enter min 10 numbers ",
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter max 12 numbers ",
                                            },
                                        })}
                                            onKeyUp={(e) => {
                                                trigger("mobileNumber");
                                                // setmobileNumberError(false)
                                                // checkMobileNumberValid(e.target.value)
                                            }}
                                            minLength={10}
                                            maxLength={10} />
                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                    </div>
                                    {errors.mobileNumber && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.mobileNumber.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type={passwordEye ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" {...register("enterPassword", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("enterPassword");

                                            }} />
                                            <div className="passwordEye"  onClick={()=> handlePassword()}><i className={passwordEye ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>
                                    {errors.enterPassword && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.enterPassword.message} </small>)}
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type={passwordEye2 ? "text" : "password"} className="form-control" id="floatingPassword1" placeholder="Confirm Password"  {...register("confirmPassword", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={(e) => {
                                                trigger("confirmPassword");

                                            }} />
                                            <div className="passwordEye"  onClick={()=> handlePassword2()}><i className={passwordEye2 ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label htmlFor="floatingPassword1">Confirm Password</label>
                                    </div>
                                    {errors.confirmPassword && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.confirmPassword.message} </small>)}
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" {...register("termsandconditions", {
                                            required: "This field is required",})} />
                                    <label className="form-check-label" htmlFor="exampleCheck1">I agree the <Link to="/">Terms and Conditions</Link></label>

                                    {errors.termsandconditions && (<div id='documentPanNumber' className='form-text text-danger'>  {errors.termsandconditions.message} </div>)}
                                    
                                    {Object.entries(signUpValidationErrors).map(c => (<div id='documentPanNumber' className='form-text text-danger'>  <b>{c[0]}</b> : {c[1]}  </div> ))}
                                    {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-info m-b-xs bluebuttoncustom" disabled={isSubmitting} >Register</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>
                            <div className="authent-login">
                                <p>Already have an account? <Link to="/login">Sign in</Link> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default RegisterPage