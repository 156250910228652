import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import axios from "axios";
import boaticon from './../../assets/images/basicbot12.png'
const ChangepasswordOld = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [successchanged, setsuccesschanged] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger, reset } = useForm({});

    // const login = () => {
    //     sessionStorage.setItem('Admintoken', "welcome")
    //     navigate("/")
    // }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }


    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        let req = {
            old_password: data.oldPassword,
            password: data.Password,
            password2: data.confirmPassword
        }
        let userinfo = JSON.parse(sessionStorage.getItem("user-info"))
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }
        axios.patch(`/apiV1/change-password/${userinfo.id}/`, req, config).then(
            (res) => {
                reset()
                setsuccesschanged(true)
                setIsSubmitting(false);

            }
        ).catch((e) => {
            setIsSubmitting(false);
            if (e.response.data.password) {
                setLoginEmailerror(e.response.data.password[0])
            }

        });
    }
    const [passwordEye, setpasswordEye] = useState(false)
    const handlePassword = () => {
        setpasswordEye(!passwordEye)
    }
    const [passwordEye2, setpasswordEye2] = useState(false)
    const handlePassword2 = () => {
        setpasswordEye2(!passwordEye2)
    }
    const [passwordEye3, setpasswordEye3] = useState(false)
    const handlePassword3 = () => {
        setpasswordEye3(!passwordEye3)
    }
    return (
        <div className="" style={{paddingTop:"20px"}}>
            <div className="card login-box-container"   style={{height:"70vh"}}>
                <div className="card-body">
                    <div className="row ">

                        <div className="col-md-6 col-lg-6 text-center mt-5" >
                            <img src={boaticon} alt="logo" style={{width:"300px"}} />
                        </div>
                        <div className="col-md-4 col-lg-4 mt-4 text-center">

                            <h2 className="mb-4" style={{color:"#000"}}>Change Your Login Credentials</h2>
                            <h6 className="mb-4" style={{color:"#000"}}>Follow the simple steps to update your password <br />and keep your account safe and secure.</h6>
                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="mb-3 text-start">
                                    <div className="form-floating">
                                        <input type={passwordEye ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" {...register("oldPassword", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("oldPassword");
                                                setLoginEmailerror("");
                                            }} />
                                        <div className="passwordEye" onClick={() => handlePassword()}><i className={passwordEye ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label for="floatingPassword">Old Password</label>
                                    </div>
                                    {errors.oldPassword && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.oldPassword.message} </small>)}


                                </div>
                                <div className="mb-3 text-start">
                                    <div className="form-floating">
                                        <input type={passwordEye2 ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" {...register("Password", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Password");
                                                setLoginEmailerror("");
                                            }} />
                                        <div className="passwordEye" onClick={() => handlePassword2()}><i className={passwordEye2 ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label for="floatingPassword">New Password</label>
                                    </div>
                                    {errors.Password && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Password.message} </small>)}


                                </div>
                                <div className="mb-3 text-start">
                                    <div className="form-floating">
                                        <input type={passwordEye3 ? "text" : "password"} className="form-control" id="confirmPassword" placeholder="Password" {...register("confirmPassword", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("confirmPassword");
                                                setLoginEmailerror("");
                                            }} />
                                        <div className="passwordEye" onClick={() => handlePassword3()}><i className={passwordEye3 ? "fas fa-eye-slash" : "fas fa-eye"}></i></div>
                                        <label for="confirmPassword">Confirm Password</label>
                                    </div>
                                    {errors.confirmPassword && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.confirmPassword.message} </small>)}
                                    {loginEmailerror && (<small id='documentPanNumber' className='form-text text-danger'>  {loginEmailerror} </small>)}
                                    {successchanged && (<small id='documentPanNumber' className='form-text text-success'>  Password Changed Successfully </small>)}

                                </div>

                                {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}

                                <div className=" text-center">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs bluebuttoncustom2" >Change Password</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ChangepasswordOld