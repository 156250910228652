import { Alert, Box, Button, Chip, Grid2, InputAdornment,  Modal,  TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import axios from "axios";
import aubrey from './../assets/circleGif/aubrey.jpg'
import diana from './../assets/circleGif/dianaGifCircle.gif'
import gala from './../assets/circleGif/galaGifCircle.gif'
import geuilia from './../assets/circleGif/geuilia.jpg'
import natasha from './../assets/circleGif/natashaGifCircle.gif'
import boaticon from './../assets/images/chartbot-bg.jpg'
// import avatar4 from './../assets/avatar4.jpeg'
import { AppBar, Toolbar, IconButton, Avatar, } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from '@mui/icons-material/MicOff';
// import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

const IntentsComponent = ({ id, type }) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const { register, handleSubmit, formState: { errors }, reset, trigger, setValue } = useForm({});
    const { handleSubmit: handleSubmit1, } = useForm({ mode: "all" });
    const { handleSubmit: handleSubmit2, } = useForm({ mode: "all" });
    const [editID, seteditID] = useState()
    const [chartInput, setChartInput] = useState()
    const [chartHistory, setchartHistory] = useState([])
    const [tagName, settagName] = useState()
    const [startForm, setstartForm] = useState(false)
    const [mutedFalse, setmutedFalse] = useState(false)
    const [apiurl, setapiurl] = useState()
    const [apitype, setapitype] = useState("GET")
    const [patterns, setpatterns] = useState()
    const [response, setresponse] = useState()
    const [patternslist, setpatternslist] = useState([])
    const [showformsinlist, setshowformsinlist] = useState(false)
    const [modeltrainsuccess, setmodeltrainsuccess] = useState(false)
    const [showformsinlist2, setshowformsinlist2] = useState(false)
    const [videoplayModal, setvideoplayModal] = useState(false)
    const [VideoGeneration, setVideoGeneration] = useState(false)
    const [intentAddedSuccess, setintentAddedSuccess] = useState(false)
    const [listofchartboats, setlistofchartboats] = useState([])
    const [custompayloads, setcustompayloads] = useState([])
    const [formslist, setformslist] = useState([])
    const [avatarsurl, setavatarsurl] = useState('')
    const [errormsg, seterrormsg] = useState()
    const [selectAvatar, setselectAvatar] = useState("")
    const [avatarid, setavatarid] = useState()
    const [avatarids, setavatarids] = useState()
    const messagesEndRef = useRef(null);
    const [addProccess, setaddProccess] = useState(false)
    const [isDefault, setisDefault] = useState(false)
    const AddCustomPayloads = () => {
        console.log('enrer')

        let custompayload = custompayloads

        setcustompayloads([...custompayload, { name: "" }])
    }
    const AddFormstonew = () => {
        let formslists = formslist
        setformslist([...formslists, {}])
    }

    const AddNewIntentstojson = () => {

        if (!addProccess) {
            setstartForm(true)
            let listofchats = listofchartboats

            listofchats.push({ name: "" })
            setlistofchartboats([...listofchats])
            setaddProccess(true)
            clearAllFields()
        } else {
            alert("You cannot add another form while one is already in progress.")
        }

    }
    const onchangeIntentvaluesonclick = (data) => {
        console.log(data.data.custom_payloads)
        setstartForm(true)
        seteditID(data.id)

        settagName(data.name)
        setValue('tagName', data.name)
        setValue('video_path', data.data.video_path)
        setValue('response', data.data.responses[0])
        if (data.data.custom_payloads) {
            setcustompayloads(data.data.custom_payloads)
        }
        if (data.data.form_details && data.data.form_status) {
            setformslist(data.data.form_details)
        }

        setpatternslist(data.data.patterns)
        setshowformsinlist(data.data.form_status)
        setshowformsinlist2(data.data.api_call)
        //setpatterns(objectid.patterns)
        setresponse(data.data.responses[0])
        if (data.data.api_call) {
            setapiurl(data.data.api_details.url)
            setapitype(data.data.api_details.method)
        }
        setisDefault(data.is_default)

    }
    const ondeleteIntentValue = (data, i) => {
        if (window.confirm("Are you sure you want to delete this intent?")) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
            axios.delete(`apiV1/intents/${data.id}/`).then((rep) => {
                GetChartboatsList(id)
            }).catch((e) => {

            })
        } else {

        }
    }
    const trainchartboattoModal = () => {
        let req = {
            chatbot_id: id,
            bot_type: type
        }
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.post(`apiV1/train-chatbot/`, req).then((rep) => {
            setmodeltrainsuccess(true)
        }).catch((e) => {

        })
    }
    const sendInputFromCustomPayload = (data) => {
        let object = {
            isuser: true,
            text: data
        }

        let charts = chartHistory
        charts.push(object)
        setchartHistory([...charts])
        let charthistory21 = charthistory2
        charthistory21.push({ human: data })
        let req = {
            chatbot_id: id,
            avatar_name:avatarid,
            chat_history: charthistory21
        }

        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1000);
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.post(`apiV1/chat-response/`, req).then((rep) => {
            if (rep.data.responses) {
                console.log(rep.data)
                let object = {
                    isuser: false,
                    text: rep.data
                }
                setChartInput("")
                let charts = chartHistory
                charts.push(object)
                setchartHistory([...charts])
                let object2 = {
                    ai: rep.data.responses[0]
                }
                let charthistory2s = charthistory2
                charthistory2s.push(object2)

                setcharthistory2([...charthistory2s])
                setTimeout(() => {
                    if (messagesEndRef.current) {
                        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 1000);

            }

        }).catch((e) => {

        })
    }
    const [charthistory2, setcharthistory2] = useState([]);
    const sendinputgetoutput = () => {
        let object = {
            isuser: true,
            text: chartInput
        }
        let charts = chartHistory
        charts.push(object)
        setchartHistory([...charts])
        let charthistory21 = charthistory2
        charthistory21.push({ human: chartInput })
        let req = {
            chatbot_id: id,
            avatar_name:avatarid,
            //question: chartInput,
            chat_history: charthistory21
        }

        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);

        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.post(`apiV1/chat-response/`, req).then((rep) => {
            if (rep.data.responses) {
                //console.log(rep.data.responses[0])
                let object = {
                    isuser: false,
                    text: rep.data
                }

                let charts = chartHistory
                charts.push(object)
                setchartHistory([...charts])

                let object2 = {
                    ai: rep.data.responses[0]
                }

                let charthistory2s = charthistory2

                charthistory2s.push(object2)

                setcharthistory2([...charthistory2s])

                setTimeout(() => {
                    setChartInput("")
                    if (messagesEndRef.current) {
                        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 300);

            }

        }).catch((e) => {

        })
    }
    const saveObjectToArrayList = (data) => {
        let pattern = []
        if(!patterns && patternslist.length == 0){
            return false
        }


        if (patternslist.length == 0 && patterns) {
            pattern = patterns.split(',')
        } else {
            pattern = patternslist
        }


        let object = {
            name: data.tagName,
            chatbot: id,
            data: {
                patterns: pattern,
                responses: [data.response],
                custom_payloads: custompayloads,
                form_status: showformsinlist,
                api_call: showformsinlist2,
                form_details: formslist,
                api_details: {
                    url: apiurl,
                    method: apitype
                }
            }
        }

        if (editID) {
            axios.patch(`apiV1/intents/${editID}/`, object).then((rep) => {
                GetChartboatsList(id);
                setaddProccess(false)
                clearAllFields();
                reset();
                setstartForm(false);
                setintentAddedSuccess(true)
                //settypesofcharts(rep.data)
            }).catch((e) => {
                if (e.response.data.detail) {
                    seterrormsg(e.response.data.detail)
                }

            })
        } else {
            axios.post('apiV1/intents/', object).then((rep) => {
                GetChartboatsList(id);
                setaddProccess(false)
                generateVideoForChat(tagName, 'add')
                clearAllFields();
                reset();
                setstartForm(false);
                setintentAddedSuccess(true)
                //settypesofcharts(rep.data)
                
            }).catch((e) => {

                if (e.response.data.detail) {
                    seterrormsg(e.response.data.detail)
                }

            })
        }
    }
    const clearAllFields = () => {
        settagName("")
        setValue('tagName', "");
        setValue("response", "")
        setcustompayloads([])
        setformslist([])
        setpatterns("")
        setresponse("")
        setpatternslist([])
        setapitype("")
        setapiurl("")
        setshowformsinlist(false)
        setshowformsinlist2(false)
    }
    const changevaluesFromid = (value, i) => {
        let custompayload = custompayloads

        custompayload[i].name = value

        setcustompayloads([...custompayload])
    }

    const changevaluesFromid2 = (value, i, type) => {
        let formslists = formslist
        formslists[i][type] = value

        setformslist([...formslists])
    }
    const handleClick = (i) => {
        let patternslists = patternslist
        patternslists.splice(i, 1);
        setpatternslist([...patternslists])
    }
    const addPatternstoList = () => {
        let patternslists = patternslist

        if (patterns) {

            let pattern = patterns.split(',')
            console.log(pattern)
            //patternslists.push(pattern);
            setpatternslist([...patternslists, ...pattern])
            setpatterns("")
        }

    }
    const [listofAvatars, setlistofAvatars] = useState([])
    const GetChartboatsList = (id) => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }

        axios.get(`apiV1/intents/?chatbot_id=${id}`).then((rep) => {
            setlistofchartboats(rep.data)
        }).catch((e) => {

        })
    }
    const getavatarsList = (id) => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }

        axios.get(`apiV1/list-avatars/?chatbot_id=${id}`).then((rep) => {
            if(rep.data){
                if(rep.data.is_avatar_selected){
                    setselectAvatar(true)
                    setavatarid(rep.data.avatar_name)
                }
            }
            setlistofAvatars(rep.data.avatars)
        }).catch((e) => {

        })

    }
    const audioConstraints = {
        audio: {
            sampleRate: 16000,  // Lower sample rate for speech
            channelCount: 1,    // Mono for smaller file sizes
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false
        }
    };
    const mediaStreamRef = useRef();
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    let recordedChunks = [];
    const audioContextRef = useRef([]);
    const analyserRef = useRef([]);
    const dataArrayRef = useRef([]);
    const [isRecording, setIsRecording] = useState(false);
    var newMediaRecorder;

    const startRecording = async () => {
        setIsRecording(true)
        const mimeTypes = [
            'audio/webm',
            'audio/webm;codecs=opus',
            'audio/mp4',
            'audio/mpeg',
            'audio/ogg',
            'audio/wav',
        ];

        let supportedMimeType = null;

        for (const mimeType of mimeTypes) {
            if (MediaRecorder.isTypeSupported(mimeType)) {
                supportedMimeType = mimeType;
                break;
            }
        }

        if (supportedMimeType) {
            console.log(`Supported mimeType: ${supportedMimeType}`);
            // Proceed with MediaRecorder using the supported mimeType
        } else {
            console.error('No supported mimeType found.');
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
            mediaStreamRef.current = stream; // Ensure the media stream is saved
            setMediaStream(stream);
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            const mediaStreamSource = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 2048;
            mediaStreamSource.connect(analyserRef.current);
            dataArrayRef.current = new Float32Array(analyserRef.current.fftSize);

            newMediaRecorder = new MediaRecorder(stream, { supportedMimeType });
            setMediaRecorder(newMediaRecorder);

            newMediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.push(event.data);
                    //setRecordedChunks((prev) => [...prev, event.data]);
                }
            };

            newMediaRecorder.onstop = async () => {
                const blob = new Blob(recordedChunks, { type: supportedMimeType });
                recordedChunks = []  // Clear chunks for the next recording
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async () => {
                    const base64Audio = reader.result.split(',')[1];  // Extract base64
                    const formData = new FormData();
                    formData.append('audio_file', base64Audio);
                    formData.append('file_extension', supportedMimeType === 'audio/mp4' ? 'mp4' : supportedMimeType === 'audio/webm' ? 'webm' : supportedMimeType === 'audio/ogg' ? 'ogg' : supportedMimeType === 'audio/mpeg' ? 'mpeg' : supportedMimeType === 'audio/wav' ? 'wav' : "mp4");
                    // API call
                    try {
                        const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
                            method: 'POST',
                            body: formData,
                        });
                        const result = await response.json();
                        sendInputFromCustomPayload(result.response)
                    } catch (error) {
                    }
                };
            }
            newMediaRecorder.start();

            //   setRecording(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };
    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorder) {
            mediaRecorder.stop();
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
            }
        }
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
            mediaStreamRef.current = null;
        }
    };
    const handleremoveCustomPayload = (data, i) => {
        let custompayload = custompayloads
        custompayload.splice(i, 1);
        setcustompayloads([...custompayload])

    }

    const removeFormsFormList = (data, i) => {
        let formslists = formslist
        formslists.splice(i, 1);
        setformslist([...formslists])
    }
    const OnclickPreviewGetLink = async () => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.get(`apiV1/preview-chatbot/?chatbot_id=${id}&avatar_name=${avatarid}`).then((rep) => {
            window.open(rep.data.page_url, "_blank")
        }).catch((e) => {

        })

    }
    const tagnamechangereflectSide = (data) => {


        let listofchats = listofchartboats
        listofchats.map((item, i) => {
            if (listofchartboats.length - 1 == i) {
                item.name = data
            }
            return item
        })

        setlistofchartboats([...listofchats])
    }
    const cancelIntentAddingProcess = () => {
        GetChartboatsList(id)
        setaddProccess(false)
        clearAllFields();
        seteditID("");
        reset();

        setstartForm(false);
    }
    const checkVideoInView = (data) => {

    }
    const onchangeFormsToShowOrHide = (check) => {
        if (check) {
            setformslist([{}])
        } else {
            setformslist([])
        }

    }
    useEffect(() => {

        GetChartboatsList(id)
        getavatarsList(id)
    }, [])
    const handleAvatarToAPI =() => {
        let req = {
            "chatbot_id" : id,
            "avatar_id" : avatarids
        }
        axios.post('apiV1/update-avatar/', req).then((rep) => {
           // alert("videos generation is in progress!!")
       }).catch((e) => {

           if (e.response.data.detail) {
               seterrormsg(e.response.data.detail)
           }

       })
    }
    const generateVideoForChat = (single, add) => {
        let req = {
            chatbot:id,
            avatar_name:avatarid,
            generate_all:single ? false : true,
        }

        if(single){
            req.intent_name = tagName
        }
        axios.post('apiV1/generate-video/', req).then((rep) => {
            if(!add){
                setVideoGeneration(true)
            } 
        }).catch((e) => {
            alert(e.response.data.message)    
        })
    }
    return (
        <>
            <><Grid2 size={{ xs: 12, md: 3, lg: 2 }} sx={{ mt: 4, }}>
                <div className="card" style={{ margin: 0 }}>
                    <div className="card-body minheightforviwe"  >
                        
                        <Button variant="contained" fullWidth size="large" onClick={() => { AddNewIntentstojson(); seteditID(""); setisDefault(false) }} disabled={avatarid && selectAvatar ? false : true}   className={avatarid && selectAvatar ? "bluebuttoncustom" : "bluebuttoncustom disableavatar"}>+ Intents</Button>
                        <div className="file-manager-menu"  >
                            <ul className="list-unstyled">
                                {listofchartboats.map((intent, i) => {
                                    return (
                                        avatarid && selectAvatar ? 
                                        <li className={intent.id == editID ? "cursor-pointer   active" : "cursor-pointer   "} key={intent.id}>
                                            <div onClick={() => onchangeIntentvaluesonclick(intent)} ><i className="far fa-folder"></i> {intent.name}</div>


                                            {intent.is_default ? "" : intent.id ? <div onClick={() => ondeleteIntentValue(intent, i)} className="text-end textrightpo" ><CancelIcon />   </div> : ""}
                                        </li> : 
                                        <li className={intent.id == editID ? "cursor-pointer   active disableavatar" : "cursor-pointer  disableavatar"} key={intent.id}>
                                            <div  ><i className="far fa-folder"></i> {intent.name}</div>


                                           
                                        </li> 

                                    )
                                })}
                            </ul>


                        </div>

                        <Button variant="contained" fullWidth size="large" onClick={() => { trainchartboattoModal() }} className={avatarid && selectAvatar ? "bluebuttoncustom" : "bluebuttoncustom disableavatar"} disabled={avatarid ? false : true}  >Train Chatbot</Button>
                        
                        <Button variant="contained" fullWidth size="large" onClick={() => { generateVideoForChat() }} disabled={avatarid && selectAvatar ? false : true}   sx={{marginTop:1}} >Generate Videos </Button>
                        
                        
                        {type == "ADVANCED_WITH_KB" ? <Button variant="contained" fullWidth size="large"  color="error" component={Link} to={`/chartbotintent?id=${id}&type=${type}`} sx={{marginTop:1}}  > Back</Button> : ""}
                    </div>
                </div>
            </Grid2>
                <Grid2 size={{ xs: 12, md: 6, lg: 7 }} sx={{ mt: 4 }}>
                    <div className="card minheightforviwe">
                        <div className="card-body">
                            {startForm ?
                                <Box
                                    component="form"
                                    sx={{ '& .MuiTextField-root': { m: 1, } }}
                                    id="mainForm"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (e.target.id === 'form4') {
                                            handleSubmit2(addPatternstoList)();
                                        } else {
                                            handleSubmit(saveObjectToArrayList)();
                                        }
                                    }}
                                >
                                    <div>

                                        <div className="mb-3 text-left">
                                            <label htmlFor="outlined-tagName" className="form-label">Tag Name</label>
                                            <input type="text" className="form-control" id="outlined-tagName" {...register("tagName", { required: "This field is required", })}
                                                onKeyUp={() => {
                                                    trigger("tagName");
                                                }}
                                                placeholder="Tag Name"
                                                onChange={(e) => tagnamechangereflectSide(e.target.value)}
                                                disabled={isDefault}
                                            />
                                            {errors.tagName && (
                                                <small className='text-danger font-bold'>
                                                    {errors.tagName.message}
                                                </small>
                                            )}
                                        </div>



                                        <div className="mb-3 text-left">
                                            <label htmlFor="outlined-Patterns" className="form-label">Patterns</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" id="outlined-Patterns" value={patterns}
                                                    onChange={(e) => setpatterns(e.target.value)}
                                                    placeholder="Patterns"

                                                    disabled={tagName == "fallback" ? true : isDefault ? false : false}
                                                />
                                                <button className="input-group-text" onClick={(e) => { e.preventDefault(); // Prevent triggering the parent form
                                                    handleSubmit2(addPatternstoList)(); }}
                                                    type="submit" id="basic-addon2" disabled={tagName == "fallback" ? true : isDefault ? false : false}>Add</button>
                                            </div>

                                            {!patterns && patternslist.length == 0  ? 
                                                <small className='text-danger font-bold'>
                                                    Please enter valid patterns
                                                </small>
                                            : ""}

                                        </div>
                                        <Box  spacing={1} sx={{marginBottom:"10px"}}>
                                        {patternslist.map((patten, i) => {
                                            return (

                                                tagName == "fallback" ? <Chip key={"che"+ i} label={patten} variant="outlined" sx={{margin:"5px"}} /> : <Chip key={i} label={patten} variant="outlined" onDelete={() => handleClick(i)} sx={{margin:"5px"}}  />
                                            )
                                        })}
                                        </Box>
                                        <div className="mb-3 text-left">
                                            <label htmlFor="outlined-response" className="form-label">Responses</label>
                                            <input type="text" className="form-control" id="outlined-response" {...register("response", { required: "This field is required", })}
                                                onKeyUp={() => {
                                                    trigger("response");
                                                }}
                                                placeholder="Responses"
                                                 
                                                disabled={tagName == "fallback" ? true : isDefault ? false : false}
                                            />
                                            {errors.response && (
                                                <small className='text-danger font-bold'>
                                                    {errors.response.message}
                                                </small>
                                            )}
                                        </div>
                                        <div className="mb-3 text-left">
                                            <label htmlFor="outlined-video_path" className="form-label">Video Url</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" id="outlined-video_path" readOnly {...register("video_path")}   />
                                                <button className="input-group-text" onClick={(e) => {
                                                    generateVideoForChat('single');
                                                }}
                                                    type="button" id="basic-addon2" disabled={tagName == "fallback" ? true : isDefault ? false : false}>Generate</button>
                                            </div>
                                        </div>
                                        {(tagName == "fallback") ? "" : <Typography variant="h6" component="h6" sx={{
                                            mx: 1,

                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>Custom Payload  <AddIcon onClick={() => AddCustomPayloads()} />  </Typography>}
                                        {custompayloads.map((item, i) => {
                                            return (
                                                <TextField

                                                    key={i}
                                                    id="outlined-required"
                                                    label="Payload"
                                                    inputProps={{
                                                        autoComplete: 'password',  // Ensure autoComplete is set to 'new-password' here
                                                    }}
                                                    value={item.name}
                                                    onChange={(e) => changevaluesFromid(e.target.value, i)}
                                                    defaultValue=""
                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => handleremoveCustomPayload(item, i)}

                                                                    edge="end"
                                                                >
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )
                                        })}

                                        {(isDefault) ? "" : <Typography variant="h6" component="h6" sx={{
                                            mx: 1,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>Forms  <Switch {...label} checked={showformsinlist} onChange={(e) => { setshowformsinlist(!showformsinlist); onchangeFormsToShowOrHide(e.target.checked) }} />    </Typography>}

                                        {/* <Typography variant="h6" component="h6" sx={{
                                                mx: 1,
                                                mt: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>Forms   </Typography> */}
                                        {formslist.map((items, i) => {
                                            return (
                                                <Grid2 key={i}>
                                                    <Box sx={{ backgroundColor: "#f5f5f5", position: "relative", padding: "15px", marginBottom: "15px" }}>
                                                        <div className="mb-3 text-left">
                                                            <label htmlFor="outlined-fallback" className="form-label">Label</label>
                                                            <input type="text" className="form-control" id="outlined-fallback"

                                                                placeholder="Label"
                                                                value={items.label}
                                                                onChange={(e) => changevaluesFromid2(e.target.value, i, "label")}
                                                                disabled={(tagName == "fallback" || tagName == "greetings") ? true : false}
                                                            />

                                                        </div>
                                                        <div className="mb-3 text-left">
                                                            <label htmlFor="validationCustom04" className="form-label">Type</label>
                                                            <select className="form-select" id="validationCustom04" value={items.type} onChange={(e) => changevaluesFromid2(e.target.value, i, "type")}>
                                                                <option   value="Charfield">Charfield</option>
                                                                <option   value="Integerfield">Integerfield</option>
                                                                <option   value="Emailfield">Emailfield</option>
                                                                 
                                                            </select>
                                                            <div className="invalid-feedback">
                                                                Please select a valid state.
                                                            </div>
                                                        </div>
                                              
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            sx={{ marginTop: "10px" }}
                                                            className="positionremoveButton"
                                                            onClick={() => removeFormsFormList(items, i)}
                                                        >
                                                            <CancelIcon />
                                                        </Button>
                                                    </Box>
                                                </Grid2>
                                            )
                                        })}
                                        {showformsinlist ?
                                            <Box textAlign={"right"}>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    sx={{ marginTop: "10px" }}
                                                    onClick={() => AddFormstonew()}

                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Box> : ""}
                                        {(isDefault) ? "" : <Typography variant="h6" component="h6" sx={{
                                            mx: 1,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>Api Requests  <Switch {...label} checked={showformsinlist2} onChange={() => setshowformsinlist2(!showformsinlist2)} />   </Typography>}

                                        {showformsinlist2 ?
                                            <>
                                                 <div className="mb-3 text-left">
                                                            <label htmlFor="outlined-ApiURL" className="form-label">Api URL</label>
                                                            <input type="text" className="form-control" id="outlined-ApiURL"

                                                                placeholder="Api URL"
                                                                value={apiurl}
                                                                onChange={(e) => setapiurl(e.target.value)}
                                                               
                                                            />

                                                        </div>
                                                        <div className="mb-3 text-left">
                                                            <label htmlFor="setapitype" className="form-label">Type</label>
                                                            <select className="form-select" id="setapitype"  value={apitype}
                                                    onChange={(e) => setapitype(e.target.value)}>
                                                                <option   value="GET">GET</option>
                                                                <option   value="POST">POST</option>
                                                                <option   value="PUT">PUT</option>
                                                                <option   value="PATCH">PATCH</option>
                                                                <option   value="DELETE">DELETE</option>
                                                                
                                                            </select>
                                                            <div className="invalid-feedback">
                                                                Please select a valid state.
                                                            </div>
                                                        </div>
                                            
                                            
                                                 

                                            </> : ""}
                                        {errormsg && <Alert severity="error" sx={{ mt: 2 }}>{errormsg}</Alert>}
                                    </div>
                                    <Box textAlign={"center"} sx={{marginTop:"15px"}}>
                                        <Button variant="contained" size="large" type="submit" className="saveButtonCustom"   >Save</Button>
                                        <Button variant="contained" color="error" type="button" size="large" sx={{ mx: 3 }} onClick={() => { cancelIntentAddingProcess() }} >Cancel</Button>
                                    </Box>

                                </Box>
                                :
                                <Box textAlign={"center"}>
                                    <img src={boaticon} style={{ maxWidth: "100%", height: "500px", margin: "0 auto" }} alt="Chat Portal"/>
                                </Box>
                            }
                        </div>
                    </div>

                </Grid2>
                <Grid2 size={{ xs: 12, md: 3, lg: 3 }} sx={{ mt: 4 }}>
                    {selectAvatar ?
                        <div className="card">
                            <div className=" ">

                                <AppBar position="static" style={{ background: 'linear-gradient(to right, #3f51b5, #2196f3)' }}>
                                    <Toolbar sx={{ paddingRight: 2 }}>
                                        <IconButton edge="start" color="inherit" aria-label="back" sx={{ padding: 0 }} onClick={() => { setselectAvatar(""); setavatarid(""); setstartForm("") }}>
                                            <ArrowBackIosIcon />
                                        </IconButton>
                                        <Avatar alt="User 1" src={avatarid == "natasha" ? natasha : avatarid == "gala" ? gala : avatarid == "diana" ? diana : avatarid == "aubrey" ? aubrey : avatarid == "geuilia" ? geuilia : gala} style={{ marginRight: 8 }} />
                                        <Typography variant="h6" style={{ flex: 1 }}>
                                            Hi There!
                                        </Typography>

                                        {/* Right section */}
                                        <Box display="flex" alignItems="center" onClick={() => { setselectAvatar(""); setavatarid("") }}>
                                            <Typography variant="h6" style={{ marginRight: 5 }}>
                                                Change
                                            </Typography>
                                            <Avatar alt="User 2" src={avatarid == "natasha" ? natasha : avatarid == "gala" ? gala : avatarid == "diana" ? diana : avatarid == "aubrey" ? aubrey : avatarid == "geuilia" ? geuilia : gala} style={{ marginRight: 8 }} />
                                            {/* <IconButton color="inherit">
                                            <MoreVertIcon />
                                        </IconButton> */}
                                            {/* <IconButton color="inherit">
                                            <KeyboardArrowDownIcon />
                                        </IconButton> */}
                                        </Box>
                                    </Toolbar>
                                </AppBar>


                                <div className="chat-container bg-white">
                                    <div className="d-flex flex-column mb-3 min-heightwithscroll">


                                        {chartHistory.map((chart, i) => {
                                            return chart.isuser ? <div className="chat-bubble user">
                                                {chart.text}
                                            </div> : <>
                                                <div className="chat-bubble bot">
                                                    {chart.text.responses[0]}

                                                </div>
                                                <ul className="custompayloads">
                                                    {chart.text.custom_payloads && chart.text.custom_payloads.map((pattern, i) => {
                                                        return (
                                                            <li key={"char"+ i} onClick={() => sendInputFromCustomPayload(pattern.name)} className="onhoverpointer">{pattern.name}</li>
                                                        )
                                                    })}

                                                </ul>
                                            </>
                                        })}
                                        <div ref={messagesEndRef} />

                                    </div>

                                    <Box
                                        sx={{
                                            backgroundColor: "#fff",

                                        }}
                                        component="form"
                                        id="form2"
                                        onSubmit={handleSubmit1(sendinputgetoutput)}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                maxWidth: 500,

                                                borderRadius: "8px",
                                                padding: "14px",
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Type your message"
                                                value={chartInput} onChange={(e) => setChartInput(e.target.value)}
                                                InputProps={{
                                                    sx: { border: "none", outline: "none" },
                                                }}
                                            />
                                            {!isRecording ? <IconButton onClick={() => startRecording()}>
                                                <MicIcon />
                                            </IconButton> :
                                                <IconButton onClick={() => stopRecording()}>
                                                    <MicOffIcon />
                                                </IconButton>}
                                            <IconButton type="submit" >
                                                <SendIcon />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ margin: "15px", paddingBottom: "15px" }}>
                                            <Button variant="contained" fullWidth type="button" className="bluebuttoncustom" onClick={() => OnclickPreviewGetLink()}> Preview </Button>
                                        </Box>
                                    </Box>
                                    {/* <div className="chat-input">
                                    <input type="text" placeholder="Type your message here..." className="form-control" value={chartInput} onChange={(e) => setChartInput(e.target.value)} />
                                    <button type="submit"  >
                                        <SendToMobile />
                                    </button>
                                    <button type="submit"  >
                                        <SendIcon />
                                    </button>
                                </div> */}

                                </div>
                            </div>
                        </div> : ""}
                    {!selectAvatar ?
                        <div className="card">
                            <div className="">
                                <ul className="avatarimage">
                                    {listofAvatars.map((avatars, h) => {
                                        return (
                                            <li key={"video" + h} onClick={() => {setavatarid(avatars.avatar_name); setavatarids(avatars.id);}} className={avatarid == avatars.avatar_name ? "active" : ""}>
                                                <video src={avatars.url} className="avatarVideoTag"></video>
                                                <button className="videopreviewclass" onClick={() => { checkVideoInView(avatars); setavatarsurl(avatars.url); setvideoplayModal(true); setmutedFalse(true) }}>Preview</button>
                                            </li>
                                        )
                                    })}

                                    {/* <li onClick={() => setavatarid(2)} className={avatarid == 2 ? "active" : ""}><img src={avatar2} alt="avatar 1" /></li>
                                    <li onClick={() => setavatarid(3)} className={avatarid == 3 ? "active" : ""}><img src={avatar3} alt="avatar 1" /></li>
                                    <li onClick={() => setavatarid(4)} className={avatarid == 4 ? "active" : ""}><img src={avatar4} alt="avatar 1" /></li> */}
                                </ul>
                                <Box sx={{
                                    margin: "15px", paddingBottom: "15px"
                                }}>

                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="button"
                                        className={avatarid ? "bluebuttoncustom" : "bluebuttoncustom disableavatar"}
                                        disabled={avatarid ? false : true}  
                                        onClick={() => { avatarid ? setselectAvatar(true) : alert("Please select your avatar"); handleAvatarToAPI(); }}
                                    >
                                        Select Avatar
                                    </Button>
                                </Box>


                            </div>

                        </div> :


                        ""}


                </Grid2>


                <Modal open={modeltrainsuccess} onClose={() => setmodeltrainsuccess(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style, width: 500 }}>
                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12}>
                                Your chart bot has train successfully
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                                <Button variant="contained" sx={{ mx: 2 }} onClick={() => setmodeltrainsuccess(false)}>Ok</Button>

                            </Grid2>
                        </Grid2>
                    </Box>
                </Modal>

                <Modal open={intentAddedSuccess} onClose={() => setintentAddedSuccess(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style, width: 500 }}>
                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12}>
                                Your chart intent saved successfully
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                                <Button variant="contained" sx={{ mx: 2 }} onClick={() => setintentAddedSuccess(false)}>Ok</Button>

                            </Grid2>
                        </Grid2>
                    </Box>
                </Modal>
                <Modal open={videoplayModal} onClose={() => setvideoplayModal(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style, width: 500 }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setvideoplayModal(false)}
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12}>
                                <video src={avatarsurl} width={"100%"} autoPlay muted={!mutedFalse} loop style={{padding:"10px"}}></video>
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                                <Button variant="contained" sx={{ mx: 2 }} onClick={() => setvideoplayModal(false)}>Ok</Button>

                            </Grid2>
                        </Grid2>
                    </Box>
                </Modal>
                <Modal open={VideoGeneration} onClose={() => setVideoGeneration(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style, width: 500 }}>
                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12}>
                                <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div>
                            </Grid2>
                            <Grid2 size={12}>
                            Videos generation is in progress!!
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={2} textAlign={"center"}>
                            <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                                <Button variant="contained" sx={{ mx: 2 }} onClick={() => setVideoGeneration(false)}>Ok</Button>

                            </Grid2>
                        </Grid2>
                    </Box>
                </Modal>
            </>
        </>
    )
}

export default IntentsComponent