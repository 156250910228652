 import './App.css';
 
import Dashboard from './Components/Dashboard';
import './assets/plugins/bootstrap/css/bootstrap.min.css'
import './assets/plugins/font-awesome/css/all.min.css'
import './assets/plugins/perfectscroll/perfect-scrollbar.css'
import './assets/css/main.css'

import { BrowserRouter as Router, Route, Routes, BrowserRouter } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import LoginPage from './Components/login/login';
import ErrorPage from './Components/ErrorPage';
import RegisterPage from './Components/login/register';
import ForgotPage from './Components/login/Forgot';
import ForgotOTPPage from './Components/login/ForgotOTP';
import Changepassword from './Components/login/changepassword';
import AccountSuccess from './Components/login/accountsuccess';
import ChangePasswordSuccess from './Components/login/passwordchange';
import ChangepasswordOld from './Components/login/changepassword2';
import InnerChartBot from './Components/InnerChartBot';
import HolderIntents from './Components/HolderIntents';
import TestApisAndEndPoints from './Components/TestApis';
import PreviewChat from './Components/PreviewChat';
import MyProfilePage from './Components/login/MyProfilePage';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
           <Route path='/' element={<PrivateRoute element={<Dashboard />} /> }></Route>  
           <Route path='/chartbotintent' element={<PrivateRoute element={<InnerChartBot />} /> }></Route>  
           <Route path='/chartbotwithkbintent' element={<PrivateRoute element={<HolderIntents />} /> }></Route>  
           <Route path='/testapis' element={<PrivateRoute element={<TestApisAndEndPoints />} /> }></Route>  
           <Route path='/login' element={<LoginPage />}></Route>  
           <Route path='/testapis2' element={<TestApisAndEndPoints />}></Route>  
           <Route path='/register' element={<RegisterPage />}></Route>  
           <Route path='/forgotpassword' element={<ForgotPage />}></Route>  
           <Route path='/forgotpassword-otp' element={<ForgotOTPPage />}></Route>  
           <Route path='/changepassword' element={<Changepassword />}></Route>  
           <Route path='/myprofile'  element={<PrivateRoute element={<MyProfilePage />} /> }  ></Route>  
           <Route path='/oldpasswordchange' element={<PrivateRoute element={<ChangepasswordOld />} /> } ></Route>  
           <Route path='/accountsuccess' element={<AccountSuccess />}></Route>  
           <Route path='/changepasswordsuccess' element={<ChangePasswordSuccess />}></Route>  
           <Route path='/preview-chat' element={<PreviewChat />}></Route>  
           <Route path='*' element={<ErrorPage />}></Route>  
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
